<template>
  <BOverlay
    :show="cargando"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <BCard>
      <BRow class="mt-0">

        <!-- CURSOS -->
        <BCol
          cols="12"
          lg="4"
        >
          <BFormGroup
            label="Curso *"
            label-for="curso"
          >
            <BOverlay
              :show="cargandoCursos"
              spinner-variant="primary"
              variant="semi-dark"
            >
              <v-select
                input-id="id_curso"
                v-model="salida.id_curso"
                placeholder="Seleccione un curso..."
                label="texto"
                :options="optionsCursos"
                :reduce="option => option.id"
                :clearable="false"
                @input="changeCurso(salida.id_curso)"
                :disabled="typeof this.salida.id !== 'undefined'"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :class="v$.salida.id_curso.$error === true
                  ? 'border-danger rounded'
                  : ''"
              />
            </BOverlay>
            <div
              v-if="v$.salida.id_curso.$error"
              id="id_cursoInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.salida.id_curso.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </BFormGroup>
        </BCol>

        <!-- ESTUDIANTES -->
        <BCol
          cols="12"
          lg="4"
        >
          <BFormGroup
            label="Estudiante *"
            label-for="estudiante"
          >
            <BOverlay
              :show="cargandoEstudiantes"
              spinner-variant="primary"
              variant="semi-dark"
            >
              <v-select
                input-id="id_persona_rol_estudiante"
                v-model="salida.id_persona_rol_estudiante"
                placeholder="Seleccione un estudiante..."
                label="texto"
                :options="optionsEstudiantes"
                :reduce="option => option.id"
                :clearable="false"
                @input="changeEstudiante(salida.id_persona_rol_estudiante)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :class="v$.salida.id_persona_rol_estudiante.$error === true
                  ? 'border-danger rounded'
                  : ''"
              />
            </BOverlay>
            <div
              v-if="v$.salida.id_persona_rol_estudiante.$error"
              id="id_persona_rol_estudianteInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.salida.id_persona_rol_estudiante.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </BFormGroup>
        </BCol>

        <!-- APODERADO -->
        <BCol
          cols="12"
          lg="4"
        >
          <BFormGroup
            label="Persona que Retira *"
            label-for="apoderado"
          >
            <!-- <BOverlay
              :show="cargandoApoderados"
              spinner-variant="primary"
              variant="semi-dark"
            >
              <v-select
                input-id="id_persona_rol_apoderado"
                v-model="salida.id_persona_rol_apoderado"
                placeholder="Seleccione un apoderado..."
                label="texto"
                :options="optionsApoderados"
                :reduce="option => option.id"
                :clearable="false"
                @input="changeCurso(salida.id_persona_rol_apoderado)"
                :disabled="optionsApoderados == 0"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :class="v$.salida.id_persona_rol_apoderado.$error === true
                  ? 'border-danger rounded'
                  : ''"
              />
            </BOverlay>
            <div
              v-if="v$.salida.id_persona_rol_apoderado.$error"
              id="id_persona_rol_apoderadoInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.salida.id_persona_rol_apoderado.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div> -->

            <BFormInput
              id="apoderado"
              v-model="apoderado_nombre"
              disabled
            />
          </BFormGroup>
        </BCol>

        <!-- FECHA -->
        <BCol
          cols="12"
          lg="4"
        >
          <BFormGroup
            label="Fecha *"
            label-for="fecha"
          >
            <!-- hastaFechaFinal -->
            <calendario
              id="fecha"
              tipo="hastaFechaActual"
              :fecha="salida.fecha"
              :state="v$.salida.fecha.$error"
              @changeCalendario="changeCalendario"
            />
              <!-- Mensajes Error Validación -->
              <BFormInvalidFeedback
                v-if="v$.salida.fecha.$error"
                id="fechaInfo"
                class="text-right"
              >
                <p v-for="error of v$.salida.fecha.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </BFormInvalidFeedback>
          </BFormGroup>
        </BCol>

        <!-- HORA -->
        <BCol
          cols="12"
          lg="4"
        >
          <BFormGroup
            label="Hora Salida *"
            label-for="hora_salida"
          >
            <hora
              id="hora"
              :showBtn="true"
              :hora.sync="salida.hora_salida"
              :state="v$.salida.hora_salida.$error"
              @changeHora="changeHoraSalida"
            />

            <!-- Mensajes Error Validación -->
            <div
              v-if="v$.salida.hora_salida.$error"
              id="hora_salidaInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.salida.hora_salida.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </BFormGroup>
        </BCol>

        <!-- HORA REGRESO -->
        <BCol
          cols="12"
          lg="4"
        >
          <BFormGroup
            label="Hora regreso *"
            label-for="hora_regreso"
          >
            <hora
              id="hora_regreso"
              :showBtn="true"
              :hora.sync="salida.hora_regreso"
              :state="v$.salida.hora_regreso.$error"
              @changeHora="changeHoraRegreso"
            />

            <!-- Mensajes Error Validación -->
            <div
              v-if="v$.salida.hora_regreso.$error"
              id="hora_regresoInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.salida.hora_regreso.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </BFormGroup>
        </BCol>

        <!-- ACUERDOS -->
        <BCol
          cols="12"
          md="12"
        >
          <BFormGroup
            label="motivo"
            label-for="motivo"
          >
            <b-form-textarea
              id="motivo"
              placeholder="Ingrese el motivo de la salida"
              v-model="salida.motivo"
            />
          </BFormGroup>
        </BCol>

      </BRow>

      <!-- FOOTER BTNS -->
      <BRow>
        <BCol
          cols="12"
          md="10"
        >
        </BCol>
        <BCol
          cols="12"
          md="2"
        >
          <btnSubmit
            class="float-right"
            variant="primary"
            :btnText="btnSubmit"
            :modulo="nombre_permiso"
            @processBtn="submitOption"
          />
        </BCol>
      </BRow>
    </BCard>
  </BOverlay>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback,
  BMedia, BButton, BAvatar, BOverlay, BFormDatepicker, BFormTimepicker,
  BFormTextarea, BAlert, BModal, BCard, BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

// AXIOS
import { mapGetters, mapActions } from 'vuex'

// FORMATOS
import { formatos } from '@/mixins/formatos'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

// COMPONENTES RECICLADOS
import calendario from '../../components/Form/calendario.vue'
import hora from '../../components/Form/hora.vue'
import btnSubmit from '../../components/Form/btnSubmit.vue'

export default {
  components: {
    // ETIQUETAS
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BMedia,
    BButton,
    BAvatar,
    BOverlay,
    BFormDatepicker,
    BFormTimepicker,
    BFormTextarea,
    BAlert,
    BModal,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    vSelect,

    // COMPONENTES RECICLADOS
    calendario,
    hora,
    btnSubmit,
  },
  mixins: [formatos],
  validations() {
    let id_curso = {}
    if (typeof this.salida.id === 'undefined') {
      id_curso = {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
      }
    }
    return {
      salida: {
        id_curso,
        fecha: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        hora_salida: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        hora_regreso: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        id_persona_rol_estudiante: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        id_persona_rol_retira: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },

      }
    }
  },
  data() {
    return {
      time: {
        time: true,
        timePattern: ['h', 'm'],
      },
      apoderado_nombre: 'El sistema cargará el apoderado',
      nombre_permiso: 'salidas',
      cargandoCursos: true,
      cargandoEstudiantes: false,
      cargandoApoderados: false,
      cargando: false,
      optionsCursos: [],
      optionsEstudiantes: [],
      optionsApoderados: [],
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getCursos: 'cursos/getCursos',
      getAlumnos: 'personas/getAlumnos',
      getPersona: 'personas/getPersona',
    }),
  },
  props: {
    btnSubmit: {
      type: String,
      Default: 'Guardar',
    },
    salida: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.cargarCursos(this.getUser.id_establecimiento)
  },
  methods: {
    ...mapActions({
      fetchCursosEstablecimiento: 'cursos/fetchCursosEstablecimiento',
      fetchAlumnos: 'personas/fetchAlumnos',
      fetchPersona: 'personas/fetchPersona',
    }),
    cargarCursos(id_establecimiento) {
      this.fetchCursosEstablecimiento(id_establecimiento).then(() => {
        this.setCursos()
      })
    },
    setCursos() {
      this.optionsCursos = []
      this.getCursos.forEach(curso => {
        const texto = `${curso.nombre} ${curso.letra}`
        this.optionsCursos.push({
          id: curso.id,
          texto,
        })
      })
      if (typeof this.salida.id !== 'undefined') { // UPDATE
        const curso = this.getCursos.find(c => c.id === this.salida.id_cursos)
        this.salida.id_curso = curso.id
        this.cargaEstudiantes(this.salida.id_curso)
      }
      if (this.getCursos.length === 1) {
        this.salida.id_curso = this.getCursos[0].id
      }
      this.cargandoCursos = false
    },
    changeCurso(id_curso) {
      this.salida.id_persona_rol_estudiante = null
      this.salida.id_persona_rol_retira = null
      this.apoderado_nombre = 'El sistema cargará el apoderado'
      this.optionsEstudiantes = []

      this.cargandoEstudiantes = true
      this.cargaEstudiantes(id_curso)
    },
    cargaEstudiantes(id_curso) {
      this.fetchAlumnos(id_curso).then(() => {
        this.setEstudiantes()
      })
    },
    setEstudiantes() {
      this.optionsEstudiantes = []
      this.getAlumnos.forEach(estudiante => {
        const texto = `${estudiante.rut}-${estudiante.dv} - ${estudiante.nombre} ${estudiante.primer_apellido}`
        this.optionsEstudiantes.push({
          id: estudiante.id_persona_rol_alumno,
          texto,
        })
      })
      if (typeof this.salida.id !== 'undefined') { // UPDATE
        const alumno = this.getAlumnos.find(a => a.id_persona_rol_alumno === this.salida.id_persona_rol_estudiante)
        this.salida.id_persona_rol_estudiante = alumno.id_persona_rol_alumno
        this.cargandoApoderado(alumno.id_persona_rol_apoderado)
      }
      this.cargandoEstudiantes = false
    },
    changeEstudiante(id_persona_rol_alumno) {
      const alumno = this.getAlumnos.find(a => a.id_persona_rol_alumno === id_persona_rol_alumno)
      this.cargandoApoderados = true
      this.cargandoApoderado(alumno.id_persona_rol_apoderado)
    },
    cargandoApoderado(id_persona_rol_apoderado) {
      this.fetchPersona(id_persona_rol_apoderado).then(() => {
        this.setApoderado()
      })
    },
    setApoderado() {
      this.salida.id_persona_rol_retira = this.getPersona.id_persona_rol
      this.apoderado_nombre = `${this.getPersona.rut}-${this.getPersona.dv} - ${this.getPersona.nombre} ${this.getPersona.primer_apellido}`
      this.cargandoApoderados = false
    },

    submitOption() {
      this.v$.salida.$touch()
      if (!this.v$.salida.$invalid) {
        this.$emit('processForm', this.salida)
      }
    },

    // CALENDARIO
    changeCalendario(fecha) {
      this.salida.fecha = fecha
    },
    // HORA
    changeHoraSalida(hora) {
      this.salida.hora_salida = hora
    },
    changeHoraRegreso(hora) {
      this.salida.hora_regreso = hora
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
